.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    justify-content: center;
    border: 1px solid black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to bottom, #00042D 0%, #022F6E 100%);
    padding: 50px 20px;
    border-radius: 8px;
    max-height: 100vh;
    width: 55%;
    min-height: 30%;
    display: flex;
    flex-direction: column;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.popup-body {
    font-size: 20px;
}

.popup-header {
    text-align: center;
    font-size: 40px;
    line-height: 120%;
}

.button_ok_container{
    align-self: center;
}

.button_ok {
    margin-left: (unset !important);
    align-content: center;
}

@media (max-width: 768px) {
    .popup-content {
        width: 100%;
        height: 100%;
    }
    .popup-body {
        height: calc(100vh - 100px);
    }
}